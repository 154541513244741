import Header from './Header';
import Footer from './Footer';
import HomePopup from './HomePopup';
import PDFPopup from './PDFPopup';
import EPrescribePopup from './EPrescribePopup';

import FormPopup from './FormPopup';
import FormPrescriber from './FormPrescriber';
import FormPatient from './FormPatient';
import FormPrescription from './FormPrescription';


export default {Header, Footer, HomePopup, PDFPopup,FormPopup,FormPrescriber,FormPatient,FormPrescription, EPrescribePopup};
