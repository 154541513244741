import React,{useState} from 'react';

import {Row, Col, Card} from 'react-bootstrap';
import PDFPopup from './../PDFPopup';
import EPrescribePopup from './../EPrescribePopup';
import PresInfoPopup from './../PresInfoPopup';

const MidTab1 = (props) => {

  const [pdf, setPdf] = useState(false);
  const [presInfo,showPresInfo] = useState(false);

  const showPDF = (e) => {
    e.preventDefault();
    setPdf(e.target.getAttribute("href"));
  }

  return <>
    <Row>
      <Col className="col-12 col-md-4 col-sm-12 title">
        PRESCRIBER MATERIALS
      </Col>
      <Col className="col-12 col-md-8 col-sm-12 text-right">

        <Row noGutters>
          <Col className="col-12 col-md-6 p-1 d-flex">
            <Card variant="light" className="text-center border-warning card-2">
              <div className="card-header f-18"> <span className="card-header-red">ePrescribe Access Only</span> <br />Important Updates for
                Prescribers
              </div>
              <Card.Body className="p-3 t-13">
                <p class="my-1 blue-text">***PLEASE BE AWARE*** <br />that the XYWAV and XYREM Prescription Forms are available for prescribers from the XYWAV and XYREM REMS website within the “Prescribe XYWAV and XYREM” tab.</p>

                <div className="btn-line mt-3 mb-2">
                  <a className="btn-img">
                    <img src={`${process.env.PUBLIC_URL}/images/Body/btn-prescribe.svg`} style={{width: "110px"}}  />
                  </a>
                </div>
                <div className="btn-line">
                  <a className="btn btn-orange btn-big" href="#" onClick={(e) => {e.preventDefault();props.tabSelect(4);}}>
                    <i className="btn-label label-new"></i>
                    <b>ePrescribe Information</b>
                    <img className="icon-arr" src={`${process.env.PUBLIC_URL}/images/Body/icon-arr.png`} />
                  </a>
                </div>
                <div className="subline text-center pb-4">or download, print, sign, and fax or mail <br />to the XYWAV and
                  XYREM REMS.</div>
              </Card.Body>
            </Card>
          </Col>

          <Col className="col-12 col-md-6 p-1 d-flex">
            <Card variant="light" className="text-center border-light h-100 ml-2">
              <Card.Body className="px-3">
                <div className="sub-title pb-3">
                  Please review the materials below before enrolling in the XYWAV and XYREM REMS
                </div>
                <a className="buttons" href={`${process.env.PUBLIC_URL}/pdfs/USPI-xywav_en.pdf`} onClick={showPDF}>Prescribing Information: <strong href={`${process.env.PUBLIC_URL}/pdfs/USPI-xywav_en.pdf`} onClick={showPDF}>XYWAV</strong></a>
                <a className="buttons" href={`${process.env.PUBLIC_URL}/pdfs/USPI-xyrem_en.pdf`} onClick={showPDF}>Prescribing Information: <strong href={`${process.env.PUBLIC_URL}/pdfs/USPI-xyrem_en.pdf`} onClick={showPDF}>XYREM</strong></a>
                <a className="buttons" href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Prescriber_Brochure.pdf`} onClick={showPDF}>Prescriber Brochure</a>
                <a className="buttons" href="#" onClick={(e) => {e.preventDefault();showPresInfo(true);}}>Prescriber Roles & Responsibilities</a>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Col>
    </Row>
    {pdf!==false && <PDFPopup pdf={pdf} onHide={() => setPdf(false)} />}
    {presInfo && <PresInfoPopup onHide={() => {showPresInfo(false);}} />}
  </>
}

export default MidTab1;
