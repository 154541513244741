import React from 'react';

import {Modal, Row, Col} from 'react-bootstrap';

const PresInfoPopup = (props) => {

  return <Modal show={true} size="xl" centered onHide={props.onHide}>
    <Modal.Header closeButton className="home-modal"><Modal.Title>XYWAV AND XYREM REMS WEBSITE OVERVIEW</Modal.Title></Modal.Header>
    <Modal.Body className="home-modal p-4">
      <Row>
       <iframe src="https://player.vimeo.com/video/465766743" width="100%" height="500" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

      </Row>
    </Modal.Body>    
  </Modal>;

}

export default PresInfoPopup;