import React from 'react';

import {Modal, Image, Alert} from 'react-bootstrap';

import _ from 'lodash';

const FeedbackPatientPopup = (props) => {

  const formMode = parseInt(_.get(props,'form',1));
  const formSettings = [
    [],
    ['REMS Enrollment Form'],
    ['REMS Enrollment Form']
  ];

  const renderFeedback = () => {
    return <div className="text-center feedback">
      <Image src="images/Body/checkmark.png" />

      <h1 className="title">Thank you!</h1>

      <div className="fs-larger">
        <p>You have successfully initiated your form processing. The remainder of the process will be completed via email through DocuSign.</p>
        <p>The Patient should check their email for a message from DocuSign to complete the enrollment process. When the Patient has signed the form, the Prescriber will receive an email from DocuSign for completion.</p>
        <p>Please do not re-submit this form. We will contact you when your form has been processed.</p>
      </div>

    </div>;
  }

  return <Modal show={true} size="lg" backdrop="static" centered onHide={props.onHide}>
    <Modal.Header  className="py-1" closeButton><Modal.Title>{formSettings[formMode][0]}</Modal.Title></Modal.Header>
    <Modal.Body className="p-5 banbg-3">
      {renderFeedback()}
    </Modal.Body>
  </Modal>;

}

export default FeedbackPatientPopup;
