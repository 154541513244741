import React from 'react';

import {Modal} from 'react-bootstrap';

import _ from 'lodash';
import FormPrescriber from './FormPrescriber';
import FormPatient from './FormPatient';
import FormPrescription from './FormPrescription';

const FormPopup = (props) => {

  const formMode = parseInt(_.get(props,'form',1));
  const formSettings = [
    [],
    ['Prescriber Enrollment Form',FormPrescriber],
    ['Patient Enrollment Form',FormPatient],
    ['Prescription Form',FormPrescription],
  ];
  const MyForm = formSettings[formMode][1];  

  return <Modal show={true} size="lg" backdrop="static" centered onHide={props.onHide}>
    <Modal.Header  className="py-1" closeButton><Modal.Title>{formSettings[formMode][0]}</Modal.Title></Modal.Header>
    <Modal.Body className="p-5 banbg-3">
      <MyForm />
    </Modal.Body>    
  </Modal>;

}

export default FormPopup;