import React, {useState} from 'react';

import {Table, Card, Image, ResponsiveEmbed} from 'react-bootstrap';
import _ from 'lodash';
import PDFPopup from './PDFPopup';
import XyremQuickStartGuide from './selectpdf/XYREMQuickStartGuide';
import XywavQuickStartGuide from './selectpdf/XYWAVQuickStartGuide';
import XyremPatientsCaregiver from './selectpdf/XYREMPatientsCaregiver';
import XywavPatientsCaregiver from './selectpdf/XYWAVPatientsCaregiver';

const Banner = (props) => {

    const ban = _.get(props, 'ban', 0);

    const [pdf, setPdf] = useState(false);

    const showPDF = (e) => {
        e.preventDefault();
        setPdf(e.target.getAttribute("href"));
    }

    const [xyrpc, setXyrpc] = useState(false);
    const showXyrpc = (e) => {
        e.preventDefault();
        setXyrpc(e.target.getAttribute("href"));
    }

    const [xywpc, setXywpc] = useState(false);
    const showXywpc = (e) => {
        e.preventDefault();
        setXywpc(e.target.getAttribute("href"));
    }

    const [xyrqsg, setXyrqsg] = useState(false);
    const showXyrqsg = (e) => {
        e.preventDefault();
        setXyrqsg(e.target.getAttribute("href"));
    }

    const [xywqsg, setXywqsg] = useState(false);
    const showXywqsg = (e) => {
        e.preventDefault();
        setXywqsg(e.target.getAttribute("href"));
    }


    const showVideo_iframe = () => <iframe style={{borderWidth: '0px'}} border="0" width="95%" height="315"
                                           src={process.env.REACT_APP_BANNERVIDEO}></iframe>;

    const showVideo = () => {
        return <ResponsiveEmbed aspectRatio="21by9" className="mx-1 mx-md-2 px-3">
            <video id="banner_video" controls src="images/Header/jazz-rems-video_low.mp4"
                   poster="images/Header/video.png">
                Your browser doesn't support HTML5 video tag.
            </video>
        </ResponsiveEmbed>;
    }


    const showBanner1 = () => <>


        <div className="d-block d-sm-none">
            <div className="title p-0 py-2 pb-3">Overview</div>
            <Card className="border-light w-100 p-0" style={{background: 'inherit'}}>

                <Table responsive borderless cellSpacing={0} cellPadding={0}>
                    <tbody>
                    <tr>
                        <td colSpan={2} className="pb-1">
                            <div className="sub-title mt-2">XYWAV and XYREM REMS</div>
                        </td>
                    </tr>
                    <tr>
                        <td className="pr-1 pt-0">
                            A REMS (Risk Evaluation and Mitigation Strategy) is a program to manage known or potential
                            serious risks associated with a drug product and is required by the FDA to ensure that the
                            benefits of the drug outweigh its risks. This program ensures that
                            XYWAV<sup>®</sup> (calcium, magnesium, potassium, and sodium oxybates) and
                            XYREM<sup>®</sup> (sodium oxybate) are dispensed only from the Certified Pharmacy.
                            <hr/>
                            The XYWAV and XYREM REMS is designed to ensure that prescribers and patients are educated on
                            and understand the risks and safe use conditions of XYWAV and XYREM and agree to follow the
                            requirements of the XYWAV and XYREM REMS. XYWAV and XYREM may only be dispensed to patients
                            enrolled in the XYWAV and XYREM REMS.
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Card>

            <Card className="border-light my-2 mx-1 p-3 h-100" style={{background: 'inherit'}}>
                <div className="sub-title mb-2">What are XYWAV and XYREM?</div>
                <p>XYWAV and XYREM are central nervous system depressants.</p>
                <ul>
                    <li>XYWAV is indicated for the treatment of cataplexy or excessive daytime sleepiness in patients 7
                        years of age and older with narcolepsy.&nbsp;</li>
                    <li>XYWAV is indicated for the treatment of idiopathic hypersomnia (IH) in adults.</li>
                    <li>XYREM is indicated for the treatment of cataplexy or excessive daytime sleepiness (EDS) in
                        patients 7 years of age and older with narcolepsy.
                    </li>
                </ul>
            </Card>

        </div>

        <div className="d-none d-sm-block">

            <div className="title p-0 py-2 pb-3">Overview</div>

            <Table className="h-100" responsive borderless>
                <tbody>
                <tr>
                    <td className="p-0 h-100 banner1part1" style={{width: "57%"}}>
                        <Card className="border-light p-0" style={{background: 'inherit'}}>

                            <Table responsive borderless cellSpacing={0} cellPadding={0}>
                                <tbody>
                                <tr>
                                    <td colSpan={2} className="pb-1">
                                        <div className="sub-title mt-2">The REMS Program</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pr-1 pt-0">A REMS (Risk Evaluation and Mitigation Strategy) is a
                                        program to manage known or potential serious risks associated with a drug
                                        product and is required by the FDA to ensure that the benefits of the drug
                                        outweigh its risks. This program ensures that XYWAV<sup>®</sup> (calcium,
                                        magnesium, potassium, and sodium oxybates) and XYREM<sup>®</sup> (sodium
                                        oxybate) are dispensed only from the Certified Pharmacy.
                                    </td>
                                    <td className="pl-1 pt-0">The XYWAV and XYREM REMS is designed to ensure that
                                        prescribers and patients are educated on and understand the risks and safe use
                                        conditions of XYWAV and XYREM and agree to follow the requirements of the XYWAV
                                        and XYREM REMS. XYWAV and XYREM may only be dispensed to patients enrolled in
                                        the XYWAV and XYREM REMS.
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card>
                    </td>
                    <td className="p-0 pr-0 h-100 banner1part2" style={{width: "43%"}}>
                        <Card className="border-light mx-1 p-3 h-100" style={{background: 'inherit'}}>
                            <div className="sub-title mb-2">What are XYWAV and XYREM?</div>
                            <p>XYWAV and XYREM are central nervous system depressants.</p>
                            <ul>
                                <li>XYWAV is indicated for the treatment of cataplexy or excessive daytime sleepiness in
                                    patients 7 years of age and older with narcolepsy.&nbsp;</li>
                                <li>XYWAV is indicated for the treatment of idiopathic hypersomnia (IH) in adults.</li>
                                <li>XYREM is indicated for the treatment of cataplexy or excessive daytime sleepiness
                                    (EDS) in patients 7 years of age and older with narcolepsy.
                                </li>
                            </ul>
                        </Card>
                    </td>
                </tr>
                </tbody>
            </Table>

        </div>
    </>


    const showBanner2 = (props) => <>

        <div className="d-block d-sm-none w-100">

            <div className="p-0 py-2 px-2 h-100">
                <div className="title">Prescriber Resources</div>
                Select the buttons below to view or download.
            </div>
            <div className="p-0 py-2">
              <Table responsive borderless className="prescriberResources-table-new">
                  <tbody>
                  <tr>
                      <td><i className="label-new label-new-big"></i></td>
                      <td className="text-center">
                          <div className="f-20 font-weight-bold"> To prescribe <span
                              className="color-yellow">XYWAV</span> or <span
                              className="color-yellow">XYREM</span> for
                              patients
                          </div>
                          <div className="f-11">XYWAV and XYREM Electronic Prescribing for Controlled
                              Substances (EPCS) meets
                              DEA requirements
                          </div>
                      </td>
                      <td><a className="car2-btn car2-btn-orange" href="#" onClick={(e) => {e.preventDefault();props.tabSelect(4);}}><b>ePrescribe Information</b><Image
                          className="icon-arr"
                          src={`${process.env.PUBLIC_URL}/images/Body/icon-arr.png`}/></a></td>
                  </tr>
                  </tbody>
              </Table>
            </div>
            <div style={{background: 'rgb(236, 236, 236)', color: 'rgb(113, 168, 213)'}}>
                <Card className="border-light p-2 w-100 h-100 my-2">
                    <div className="sub-title2 mb-2">Enrollment</div>
                    <a className="car2-btn"
                       href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Prescriber_Enrollment_Form.pdf`}
                       onClick={showPDF}>Prescriber Enrollment Form</a>
                    <a className="car2-btn"
                       href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Patient_Enrollment_Form.pdf`}
                       onClick={showPDF}>Patient Enrollment Form</a>
                </Card>
            </div>

            <div style={{background: 'rgb(236, 236, 236)', color: 'rgb(113, 168, 213)'}}>
                <Card className="border-light p-2 w-100 h-100 my-2">
                    <div className="sub-title2 mb-2">Prescribing Information</div>
                    <a className="car2-btn" href={`${process.env.PUBLIC_URL}/pdfs/USPI-xywav_en.pdf`}
                       onClick={showPDF}>XYWAV</a>
                    <a className="car2-btn" href={`${process.env.PUBLIC_URL}/pdfs/USPI-xyrem_en.pdf`}
                       onClick={showPDF}>XYREM</a>
                </Card>
            </div>

            <div style={{background: 'rgb(236, 236, 236)', color: 'rgb(113, 168, 213)'}}>
                <Card className="border-light p-2 w-100 h-100 my-2">
                    <div className="sub-title2 mb-2">Prescription Forms</div>
                    <Image src="images/Header/prescription.png" className="mx-auto my-3"/>
                    <a className="car2-btn" href={`${process.env.PUBLIC_URL}/pdfs/XYWAV_Prescription_Form.pdf`}
                       onClick={showPDF}>For XYWAV</a>
                    <a className="car2-btn" href={`${process.env.PUBLIC_URL}/pdfs/XYREM_Prescription_Form.pdf`}
                       onClick={showPDF}>For XYREM</a>
                    <a className="car2-btn car2-btn-orange" href="#" onClick={(e) => {e.preventDefault();props.tabSelect(4);}}><b>ePrescribe Information</b>
                      <Image className="icon-arr" src={`${process.env.PUBLIC_URL}/images/Body/icon-arr.png`} /></a>
                </Card>
            </div>

            <div style={{background: 'rgb(236, 236, 236)', color: 'rgb(113, 168, 213)'}}>
                <Card className="border-light p-2 w-100 h-100 my-2">
                    <div className="sub-title2 mb-2">XYWAV and XYREM Prescriber Brochure</div>
                    <Image src="images/Header/brochure.png" className="mx-auto my-2"/>
                    <a href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Prescriber_Brochure.pdf`}
                       onClick={showPDF} className="car2-btn">Download</a>
                </Card>
            </div>

        </div>
        <div className="d-none d-sm-block">
            <Table className="h-100" responsive borderless>
                <tbody>
                <tr>
                    <td colSpan="3" className="p-0 py-2 pb-1 h-100">
                        <span className="title">Prescriber Resources</span> &nbsp;&nbsp;&nbsp;
                        Select the buttons below to view or download.
                    </td>
                </tr>
                <tr>
                    <td colSpan="3" className="p-0 py-2 pb-3 h-100">
                        <Table responsive borderless className="prescriberResources-table-new">
                            <tbody>
                            <tr>
                                <td><i className="label-new label-new-big"></i></td>
                                <td className="text-center">
                                    <div className="f-20 font-weight-bold"> To prescribe <span
                                        className="color-yellow">XYWAV</span> or <span
                                        className="color-yellow">XYREM</span> for
                                        patients
                                    </div>
                                    <div className="f-11">XYWAV and XYREM Electronic Prescribing for Controlled
                                        Substances (EPCS) meets
                                        DEA requirements
                                    </div>
                                </td>
                                <td><a className="car2-btn car2-btn-orange" href="#" onClick={(e) => {e.preventDefault();props.tabSelect(4);}}><b>ePrescribe Information</b><Image
                                    className="icon-arr"
                                    src={`${process.env.PUBLIC_URL}/images/Body/icon-arr.png`}/></a></td>
                            </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>
                <tr>
                    <td className="p-0 h-100" style={{width: '33%'}}>
                        <div className="h-50 pb-1">
                            <Card className="border-light p-2 h-100"
                                  style={{background: 'rgb(236, 236, 236)', color: 'rgb(113, 168, 213)'}}>
                                <div className="sub-title2 mb-2">Enrollment</div>
                                <a className="car2-btn"
                                   href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Prescriber_Enrollment_Form.pdf`}
                                   onClick={showPDF}>Prescriber Enrollment Form</a>
                                <a className="car2-btn"
                                   href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Patient_Enrollment_Form.pdf`}
                                   onClick={showPDF}>Patient Enrollment Form</a>
                            </Card>
                        </div>
                        <div className="h-50 pt-1">
                            <Card className="border-light p-2 h-100"
                                  style={{background: 'rgb(236, 236, 236)', color: 'rgb(113, 168, 213)'}}>
                                <div className="sub-title2 mb-2">Prescribing Information</div>
                                <a className="car2-btn" href={`${process.env.PUBLIC_URL}/pdfs/USPI-xywav_en.pdf`}
                                   onClick={showPDF}>XYWAV</a>
                                <a className="car2-btn" href={`${process.env.PUBLIC_URL}/pdfs/USPI-xyrem_en.pdf`}
                                   onClick={showPDF}>XYREM</a>
                            </Card>
                        </div>
                    </td>
                    <td className="p-0 h-100" style={{width: '33%'}}>
                        <Card className="border-light mx-2 p-3 h-100"
                              style={{background: 'rgb(236, 236, 236)', color: 'rgb(113, 168, 213)'}}>
                            <div className="sub-title2 mb-2">Prescription Forms</div>
                            <Image src={`${process.env.PUBLIC_URL}/images/Header/prescription.png`}
                                   className="mx-auto my-3"/>
                            <a className="car2-btn" href={`${process.env.PUBLIC_URL}/pdfs/XYWAV_Prescription_Form.pdf`}
                               onClick={showPDF}>For XYWAV</a>
                            <a className="car2-btn" href={`${process.env.PUBLIC_URL}/pdfs/XYREM_Prescription_Form.pdf`}
                               onClick={showPDF}>For XYREM</a>
                          <a className="car2-btn car2-btn-orange" href="#" onClick={(e) => {e.preventDefault();props.tabSelect(4);}}><b>ePrescribe Information</b>
                            <Image className="icon-arr" src={`${process.env.PUBLIC_URL}/images/Body/icon-arr.png`} /></a>
                        </Card>
                    </td>
                    <td className="p-0 h-100" style={{width: '33%'}}>
                        <Card className="border-light mr-2 p-3 h-100"
                              style={{background: 'rgb(236, 236, 236)', color: 'rgb(113, 168, 213)'}}>
                            <div className="sub-title2 mb-2">XYWAV and XYREM Prescriber Brochure</div>
                            <Image src={`${process.env.PUBLIC_URL}/images/Header/brochure.png`}
                                   className="mx-auto my-2"/>
                            <a href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Prescriber_Brochure.pdf`}
                               onClick={showPDF} className="car2-btn">Download</a>
                        </Card>
                    </td>
                </tr>


                </tbody>
            </Table>
        </div>
    </>;


    const showBanner3 = () => <>

        <div className="d-block d-sm-none">
            <div className="p-0 py-2 pb-3 w-100 h-100">
                <div className="title">Patient/Caregiver Resources</div>
            </div>

            <Card className="border-light p-2 h-100 my-2" style={{background: '#ececec', color: '#71a8d5'}}>
                <div className="sub-title3 mb-2">Patient<br/>Quick Start Guide</div>
                <a className="car2-btn"
                   href="javascript:;" onClick={showXywqsg}>For
                    XYWAV</a>
                <a className="car2-btn"
                   href="javascript:;" onClick={showXyrqsg}>For
                    XYREM</a>
            </Card>

            <Card className="border-light p-2 h-100 my-2" style={{background: '#ececec', color: '#71a8d5'}}>
                <div className="sub-title3 mb-2">Brochure for Pediatric<br/>Patients and their Caregiver</div>
                <a className="car2-btn"
                   onClick={showXywpc}>For XYWAV</a>
                <a className="car2-btn"
                   onClick={showXyrpc}>For XYREM</a>
            </Card>

            <div className="my-2" style={{height: "100%", overflowY: "auto"}}>

                <b>What You Should Know</b>
                <ul className="banner3">
                    <li>All patients must be enrolled in the XYWAV and XYREM REMS by the patient's heathcare provider.
                    </li>
                    <li>Prescriptions for XYWAV and XYREM are only dispensed and shipped to patients who are enrolled in
                        the REMS with documentation of safe use conditions.
                    </li>
                    <li>Each pediatric patient must have a caregiver who is counseled by the healthcare provider.</li>
                    <li>XYWAV and XYREM will be dispensed only by the REMS Certified Pharmacy.</li>
                </ul>
            </div>

        </div>
        <div className="d-none d-sm-block">

            <Table className="h-100" responsive borderless>
                <tbody>
                <tr>
                    <td colSpan="2" className="p-0 py-2 pb-3 h-100">
                        <div className="title">Patient/Caregiver Resources</div>
                    </td>
                </tr>
                <tr>
                    <td className="p-0 h-100" style={{width: '25%'}}>
                        <div className="h-50 pb-1">
                            <Card className="border-light p-2 h-100" style={{background: '#ececec', color: '#71a8d5'}}>
                                <div className="sub-title3 mb-2">Patient<br/>Quick Start Guide</div>
                                <a className="car2-btn"
                                   href="javascript:;" onClick={showXywqsg}>For XYWAV</a>
                                <a className="car2-btn"
                                   href="javascript:;"
                                   onClick={showXyrqsg}>For XYREM</a>
                            </Card>
                        </div>
                        <div className="h-50 pt-1">
                            <Card className="border-light p-2 h-100" style={{background: '#ececec', color: '#71a8d5'}}>
                                <div className="sub-title3 mb-2">Brochure for Pediatric<br/>Patients and their Caregiver
                                </div>
                                <a className="car2-btn"
                                   href="javascript:;" onClick={showXywpc}>For XYWAV</a>
                                <a className="car2-btn"
                                   href="javascript:;"
                                   onClick={showXyrpc}>For XYREM</a>
                            </Card>
                        </div>
                    </td>
                    <td className="px-2 h-100" style={{width: '75%'}}>
                        <div style={{height: "100%", overflowY: "auto"}}>

                            <b>What You Should Know</b>
                            <ul className="banner3">
                                <li>All patients must be enrolled in the XYWAV and XYREM REMS by the patient's heathcare
                                    provider.
                                </li>
                                <li>Prescriptions for XYWAV and XYREM are only dispensed and shipped to patients who are
                                    enrolled in the REMS with documentation of safe use conditions.
                                </li>
                                <li>Each pediatric patient must have a caregiver who is counseled by the healthcare
                                    provider.
                                </li>
                                <li>XYWAV and XYREM will be dispensed only by the REMS Certified Pharmacy.</li>
                            </ul>
                        </div>
                    </td>
                </tr>
                </tbody>
            </Table>
        </div>

    </>

    return <div className="banner-area h-100 d-flex align-items-center">
        {ban === 0 && showVideo()}
        {ban === 1 && showBanner1()}
        {ban === 2 && showBanner2(props)}
        {ban === 3 && showBanner3()}
        {pdf !== false && <PDFPopup pdf={pdf} onHide={() => setPdf(false)}/>}
        {xyrqsg !== false && <XyremQuickStartGuide xyrqsg={xyrqsg} onHide={() => setXyrqsg(false)}/>}
        {xyrpc !== false && <XyremPatientsCaregiver xyrpc={xyrpc} onHide={() => setXyrpc(false)}/>}
        {xywqsg !== false && <XywavQuickStartGuide xywqsg={xywqsg} onHide={() => setXywqsg(false)}/>}
        {xywpc !== false && <XywavPatientsCaregiver xywpc={xywpc} onHide={() => setXywpc(false)}/>}

    </div>;

}

export default Banner;
