import React from 'react';

import {Modal, Row, Col} from 'react-bootstrap';

const PresInfoPopup = (props) => {

    return <Modal show={true} size="xl" centered onHide={props.onHide}>
        <Modal.Header closeButton className="home-modal"><Modal.Title>Prescriber Roles & Responsibilities</Modal.Title></Modal.Header>
        <Modal.Body className="home-modal p-4">
            <Row className="flex-column flex-sm-row">
                <Col className="px-3">
                    <b>Prescribers enrolled in the XYWAV and XYREM REMS agree to perform the following:</b>
                    <ol className="px-3">
                        <li className="py-2">Review the Prescribing Information (PI) and the XYWAV and XYREM REMS
                            Prescriber Brochure.
                        </li>
                        <li className="py-2">Screen each patient for:
                            <ul className="px-3">
                                <li className="py-2">History of alcohol or substance abuse</li>
                                <li className="py-2">History of sleep-related breathing disorders</li>
                                <li className="py-2">History of compromised respiratory function</li>
                                <li className="py-2">Concomitant use of sedative hypnotics, other CNS depressants, or
                                    other potentially interacting agents
                                </li>
                                <li className="py-2">History of depression or suicidality</li>
                            </ul>
                        </li>
                        <li className="py-2">Counsel each patient prior to initiating therapy with XYWAV or XYREM on the
                            serious risks and safe use and handling of XYWAV and XYREM using the appropriate XYWAV and
                            XYREM REMS patient materials: XYWAV or XYREM Patient Quick Start Guides (for adults) or the
                            XYWAV or XYREM Brochure for Pediatric Patients and their Caregivers.
                        </li>
                        <li className="py-2">Enroll each patient in the XYWAV and XYREM REMS by completing the XYWAV and
                            XYREM REMS Patient Enrollment Form and submitting the form to the XYWAV and XYREM REMS.
                        </li>
                        <li className="py-2">Evaluate each patient within the first 3 months of starting XYWAV and XYREM
                            therapy, including an evaluation of the following. It is recommended that patients be
                            re-evaluated every 3 months thereafter while taking XYWAV and XYREM.
                            <ul className="px-3">
                                <li className="py-2">Concomitant use of sedative hypnotics, other CNS depressants, or
                                    other potentially interacting agents
                                </li>
                                <li className="py-2">Serious adverse events</li>
                                <li className="py-2">Signs of abuse and misuse, including:
                                    <ol className="px-3">
                                        <li className="py-2">An increase in dose or frequency of dosing</li>
                                        <li className="py-2">Reports of lost, stolen, or spilled medication</li>
                                        <li className="py-2">Drug-seeking behavior</li>
                                    </ol>
                                </li>
                            </ul>
                        </li>
                        <li className="py-2">Report all potential serious adverse events, including CNS depression,
                            respiratory depression, loss of consciousness, coma, death, and any cases of suspected
                            abuse, misuse, or diversion to Jazz Pharmaceuticals.
                        </li>
                    </ol>
                </Col>
                <Col className="px-3">
                    <b>Each time a new prescription is written the prescriber will complete the XYWAV or XYREM
                        Prescription Form and submit it to the XYWAV and XYREM REMS. By completing and signing this
                        form, the prescriber acknowledges:</b>
                    <ol className="p-3">
                        <li className="py-2">Having an understanding of:
                            <ul className="px-3">
                                <li className="py-2"><b>The approved indications for XYWAV:</b>
                                    <ul className="px-3">
                                        <li className="py-2">Treatment of cataplexy in narcolepsy or treatment of
                                            excessive daytime sleepiness in narcolepsy in patients 7 years and older
                                        </li>
                                        <li className="py-2">Treatment of idiopathic hypersomnia in adults</li>

                                    </ul>
                                </li>
                                <li className="py-2"><b>The approved indications for XYREM:</b>
                                    <ul className="px-3">
                                        <li className="py-2">Treatment of cataplexy in narcolepsy or treatment of
                                            excessive daytime sleepiness in narcolepsy in patients 7 years and older
                                        </li>

                                    </ul>
                                </li>
                                <li className="py-2">The serious risks associated with XYWAV and XYREM</li>
                                <li className="py-2">The Prescribing Information and XYWAV and XYREM REMS Prescriber
                                    Brochure
                                </li>
                            </ul>
                        </li>
                        <li className="py-2">Having screened the patient for the following:
                            <ul className="px-3">
                                <li className="py-2">History of alcohol or substance abuse</li>
                                <li className="py-2">History of sleep-related breathing disorders</li>
                                <li className="py-2">History of compromised respiratory function</li>
                                <li className="py-2">Concomitant use of sedative hypnotics, other CNS depressants, or
                                    other potentially interacting agents
                                </li>
                                <li className="py-2">History of depression or suicidality</li>
                            </ul>
                        </li>
                        <li className="py-2">Having counseled the patient on:
                            <ul className="px-3">
                                <li className="py-2">The serious risks associated with XYWAV and XYREM Contraindications
                                    (alcohol and sedative hypnotics) and implications of concomitant use of XYWAV and
                                    XYREM with other potentially interacting agents
                                </li>
                                <li className="py-2">Preparation and dosing instructions for XYWAV and XYREM</li>
                                <li className="py-2">Risk of abuse and misuse associated with XYWAV and XYREM</li>
                                <li className="py-2">Risk of operating hazardous machinery including automobiles or
                                    airplanes for the first 6 hours after taking a dose of XYWAV and XYREM
                                </li>
                                <li className="py-2">Safe use, handling, and storage of XYWAV and XYREM</li>
                            </ul>
                        </li>
                        <li className="py-2">That XYWAV and XYREM is medically appropriate for the patient</li>
                        <li className="py-2">Having listed all known prescription and nonprescription medications and
                            doses on the XYWAV or XYREM Prescription Forms
                        </li>
                    </ol>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>;

}

export default PresInfoPopup;
