import React from 'react';

import {Modal} from 'react-bootstrap';

const FaqPopup = (props) => {

  return <Modal show={true} size="xl" centered onHide={props.onHide}>
    <Modal.Header closeButton className="home-modal"><Modal.Title>FAQ</Modal.Title></Modal.Header>
    <Modal.Body className="home-modal p-4">
      <div>
        <b>Is it still possible to enroll offline?</b><br />
        <p>Yes, you can still enroll offline. Please download the PDF version of this form and follow the submission instructions.</p>
      </div>

      <div>
        <b>How do I add my signature to an online enrollment?</b><br />
        <p>This is done securely and easily through DocuSign.</p>
      </div>

      <div>
        <b>Do I need a DocuSign account to complete the online enrollment process?</b><br />
        <p>No. You do not need a DocuSign account to complete the online enrollment process.</p>
      </div>

      <div>
        <b>Do I need to complete the online version of the prescriber enrollment form if I am already enrolled into the XYWAV and XYREM REMS?</b><br />
        <p>No. If you are already enrolled into the XYWAV and XYREM REMS or the Xyrem REMS or have completed the prescriber enrollment process offline and faxed it to the Certified Pharmacy, you do not need to complete the online version of the prescriber enrollment form. </p>
        <p>You can still use the online enrollment form to enroll your patients.</p>
      </div>

      <div>
        <b>How long does it take to process my enrollment so I can enroll patients?</b><br />
        <p>Generally, the Certified Pharmacy will process your enrollment within 2 to 3 business days.</p>
      </div>

      <div>
        <b>How will patients be notified that they need to complete their portion of the enrollment form?</b><br />
        <p>Once you initiate the patient enrollment form, an email notification will be sent to your patient. The email will include a link to the enrollment form on DocuSign, where your patient can securely complete their portion of the enrollment process.</p>
        <p>If your patient does not complete their portion of the enrollment form, we will send them a series of notification reminder emails.</p>
      </div>

      <div>
        <b>How can I help my patient complete their portion of the enrollment form?</b><br />
        <p>Due to security and privacy reasons, patients must fill out the entire portion of their form. If additional assistance is needed, we recommend you assist them with the process through a phone call.</p>
      </div>

      <div>
        <b>Will a patient be able to include a copy of their insurance identification card?</b><br />
        <p>If a patient confirms that they have prescription coverage on the enrollment form, they will be able to include a copy of their insurance identification card as an attachment.</p>
        <p>If patients are completing the enrollment form on a mobile device, they will be able to use the camera to take a picture of their identification card.</p>
      </div>

      <div>
        <b>How do I know my patient’s information is safe?</b><br />
        <p>DocuSign provides full document encryption to ensure the security and privacy of your data. Documents stored in their ISO 27001 and SSAE 16 data centers are encrypted with the highest levels of encryption.</p>
      </div>

      <div>
        <b>Will my or my patient’s information be shared with any third parties?</b><br />
        <p>No. Your or your patient’s information will be kept strictly confidential and never be shared or sold to third parties.</p>
      </div>

    </Modal.Body>
  </Modal>;

}

export default FaqPopup;
