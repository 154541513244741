import React,{useState,useEffect, useRef} from 'react';

import {Row, Col, Card, Table, Image, Button} from 'react-bootstrap';

import FormPopup from './../FormPopup';

const MidTab4 = (props) => {

  const [pdf, setPdf] = useState(false);
  const [form, setForm] = useState(false);
  const patientenrollment = useRef(null);
  const scrollToPatientenrollment = () => {window.scrollTo(0, patientenrollment.current.scrollHeight + 130)}

  const initPage = () => {
    if(window.location.hash==='#patientenrollment'){
      setTimeout(scrollToPatientenrollment, 1000);
      //proscriberenrollment.current?.scrollIntoView({ block: "nearest", behavior: 'smooth' });
    }
  }

  useEffect(initPage,[]);

  const showPDF = (e) => {
    e.preventDefault();
    setPdf(e.target.getAttribute("href"));
  }

  return <>
    <Row ref={patientenrollment}>
      <Col className="col-12 col-md-4 col-sm-12 title">
        <label className="red">For Prescriber Use Only</label>
        PATIENT ENROLLMENT
        <div className="title-details mt-2">
          <p>If you begin patient enrollment online, your patient will receive an e-mail to complete his/her portions of the form, including an e-signature. Then, you will submit the form to the Certified Pharmacy. Both you and your patient will be notified when enrollment in the program is successful.</p>

        </div>
      </Col>
      <div className="d-sm-none mb-3">
        <label className="red">For Prescriber Use Only</label>
        <div className="title-details mt-2">
          <p>If you begin patient enrollment online, your patient will receive an e-mail to complete his/her portions of the form, including an e-signature. Then, you will submit the form to the Certified Pharmacy. Both you and your patient will be notified when enrollment in the program is successful.</p>

        </div>
      </div>
      <Col className="col-12 col-md-8 col-sm-12 text-right bg-light p-3">
        <p className="title-details text-left p-3 mx-5">If you choose Option 1, have your patient sign the enrollment form in your office. You will then submit it to the Certified Pharmacy by e-mail, fax, or mail. For best results please use <b>Adobe Acrobat</b> to fill out the forms.</p>

        <Row noGutters>
          <Col className="col-12 col-lg-6 text-center blue-text">
            <label className="red px-5 py-1">Option 1</label>
            <Card variant="light" className="h-100">
              <Card.Body className="pb-0">
                <Table responsive borderless>
                  <tbody>
                  <tr>
                    <td className="w-30 p-0"></td>
                    <td className="w-30 p-0"><img src={`${process.env.PUBLIC_URL}/images/Body/icon-download.png`} /></td>
                    <td className="p-0 pl-1 text-left">
                      <b>Download:</b><br />
                      <a target="_blank" href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Patient_Enrollment_Form.pdf`}>Patient Enrollment Form</a>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="h-10"></td>
                  </tr>
                  <tr>
                    <td className="w-30 p-0"></td>
                    <td className="w-30 p-0"><img src={`${process.env.PUBLIC_URL}/images/Body/icon-scan-email.png`} /></td>
                    <td className="p-0 pl-1 text-left">
                      <b>Scan and Email to:</b><br />
                      <a href="mailto: ESSDSPrescribers@express-scripts.com">ESSDSPrescribers@express-scripts.com</a>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="h-10"></td>
                  </tr>
                  <tr>
                    <td className="w-30 p-0"><img  src={`${process.env.PUBLIC_URL}/images/Body/icon-or.png`} /></td>
                    <td className="w-30 p-0"><img  src={`${process.env.PUBLIC_URL}/images/Body/icon-fax.png`} /></td>
                    <td className="p-0 pl-1 text-left">
                      <b>Fax to:<br />XYWAV and XYREM REMS</b><br />1-866-470-1744 (toll free)
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="h-10"></td>
                  </tr>
                  <tr>
                    <td className="w-30 p-0"><img  src={`${process.env.PUBLIC_URL}/images/Body/icon-or.png`} /></td>
                    <td className="w-30 p-0"><img  src={`${process.env.PUBLIC_URL}/images/Body/icon-mail.png`} /></td>
                    <td className="p-0 pl-1 text-left">
                      <b>Mail to:<br />XYWAV and XYREM REMS</b> <br />PO Box 66589<br />St. Louis, MO 63166-6589
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col className="col-12 col-lg-6 text-center blue-text">
            <label className="red px-5 py-1 mt-5 mt-lg-0">Option 2</label>

            <Card className="ml-1 h-100">
              <Card.Body className="text-center">
              <p><b>Submit using DocuSign</b><br />
                By using DocuSign, the XYWAV and XYREM REMS can ensure that your personal information can stay safe, secure, and protected.</p>

                <Image fluid src="images/Body/icon-computer.png" className="px-5" />

                <a className="buttons px-5" href="#" onClick={(e) => {e.preventDefault();setForm(2);}}><b>ACCESS ONLINE PATIENT ENROLLMENT FORM</b></a>
              </Card.Body>
            </Card>

          </Col>
        </Row>

      </Col>
    </Row>
    {form!==false && <FormPopup form={form} onHide={() => setForm(false)} />}
  </>
}

export default MidTab4;
