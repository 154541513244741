import React from 'react';

import {Modal} from 'react-bootstrap';

const EPrescribePopup = (props) => {

  let drFirst = (window.location.hostname == 'xywavxyremrems.com' || window.location.hostname == 'www.xywavxyremrems.com') ? 'https://jazzerx.drfirst.com/' : 'https://jazzerx.staging.drfirst.com/' ;

  return <Modal show={true} size="xxl" centered onHide={props.onHide}>
    <Modal.Header  className="py-0" closeButton><Modal.Title>ePrescribe XYWAV and XYREM</Modal.Title></Modal.Header>
    <Modal.Body className="p-0 vh-80">
      <iframe style={{borderWidth: 0}} width="100%" height="100%" src={drFirst}></iframe>
    </Modal.Body>
  </Modal>;

}

export default EPrescribePopup;
