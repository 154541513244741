import React,{useState} from 'react';

import {Row, Col, Image} from 'react-bootstrap';

//import PDFPopup from './../PDFPopup';
import XyremQuickStartGuide from "../selectpdf/XYREMQuickStartGuide";
import XyremPatientsCaregiver from "../selectpdf/XYREMPatientsCaregiver";
import XywavQuickStartGuide from "../selectpdf/XYWAVQuickStartGuide";
import XywavPatientsCaregiver from "../selectpdf/XYWAVPatientsCaregiver";

const MidTab2 = (props) => {

  // const [pdf, setPdf] = useState(false);
  //
  // const showPDF = (e) => {
  //   e.preventDefault();
  //   setPdf(e.target.getAttribute("href"));
  // }

  const [xyrpc, setXyrpc] = useState(false);
  const showXyrpc = (e) => {
    e.preventDefault();
    setXyrpc(e.target.getAttribute("href"));
  }

  const [xywpc, setXywpc] = useState(false);
  const showXywpc = (e) => {
    e.preventDefault();
    setXywpc(e.target.getAttribute("href"));
  }

  const [xyrqsg, setXyrqsg] = useState(false);
  const showXyrqsg = (e) => {
    e.preventDefault();
    setXyrqsg(e.target.getAttribute("href"));
  }

  const [xywqsg, setXywqsg] = useState(false);
  const showXywqsg = (e) => {
    e.preventDefault();
    setXywqsg(e.target.getAttribute("href"));
  }


  return <>
    <Row>
      <Col className="col-12 col-md-4 col-sm-12 title">
        PATIENT MATERIALS
        <div className="title-details mt-2">
          Before enrolling, patients can use the Quick Start Guide and Brochure for Pediatric Patients and Their Caregivers on the right to find out more concerning the use of XYWAV and XYREM.
        </div>
      </Col>
      <div className="d-sm-none mb-3 text-left">
        Before enrolling, patients can use the Quick Start Guide and Brochure for Pediatric Patients and Their Caregivers on the right to find out more concerning the use of XYWAV and XYREM.
      </div>
      <Col className="col-12 col-md-8 col-sm-12 text-right bg-light p-3">
        <Row>
          <Col className="col-12 col-md-6  text-center">

            <Image fluid src="images/Body/Patient-Materials-1.png" />

            <div style={{minHeight: 50}}><b>Brochure for Pediatric Patients And their Caregivers</b></div>

            <p>Use the Caregiver Brochure to counsel caregivers of pediatric patients.</p>

            <Row className="mb-3">
              <Col className="col-12 col-xl-6 pb-4 pb-xl-0 text-center">
                <a className="blue-buttons" href="javascript:;" onClick={showXywpc} >For XYWAV</a>
              </Col>
              <Col className="col-12 col-xl-6 text-center">
                <a className="blue-buttons" href="javascript:;" onClick={showXyrpc} >For XYREM</a>
              </Col>
            </Row>

          </Col>
          <Col className="col-12 col-md-6  text-center">

            <Image fluid src="images/Body/Patient-Materials-2.png" />

            <div style={{minHeight: 50}}><b>Patient Quick Start Guide</b></div>

            <p>Click below to read the Patient Quick Start Guide for counseling adult patients.</p>
            <Row className="mb-3">
              <Col className="col-12 col-xl-6  pb-4 pb-xl-0 text-center">
                <a className="blue-buttons" href="javascript:;" onClick={showXywqsg}>For XYWAV</a>
              </Col>
              <Col className="col-12 col-xl-6 text-center">
                <a className="blue-buttons" href="javascript:;"
                   onClick={showXyrqsg} >For XYREM</a>
              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
    {/*{pdf!==false && <PDFPopup pdf={pdf} onHide={() => setPdf(false)} />}*/}
    {xyrqsg !== false && <XyremQuickStartGuide xyrqsg={xyrqsg} onHide={() => setXyrqsg(false)}/>}
    {xyrpc !== false && <XyremPatientsCaregiver xyrpc={xyrpc} onHide={() => setXyrpc(false)}/>}
    {xywqsg !== false && <XywavQuickStartGuide xywqsg={xywqsg} onHide={() => setXywqsg(false)}/>}
    {xywpc !== false && <XywavPatientsCaregiver xywpc={xywpc} onHide={() => setXywpc(false)}/>}
  </>
}

export default MidTab2;
