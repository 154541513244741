import React from 'react';

import {Modal, Image, Alert} from 'react-bootstrap';

import _ from 'lodash';

const FeedbackPopup = (props) => {

  const formMode = parseInt(_.get(props,'form',1));
  const formSettings = [
    [],
    ['REMS Enrollment Form'],
    ['REMS Enrollment Form']
  ];  

  const renderFeedback = () => {
    return <div className="text-center feedback">
      <Image src="images/Body/checkmark.png" />
      
      <h1 className="title">Thank you!</h1>

      <p>You have successfully initiated your form processing. We will contact you when your form has been processed.</p>
      
    </div>;
  }

  return <Modal show={true} size="lg" backdrop="static" centered onHide={props.onHide}>
    <Modal.Header  className="py-1" closeButton><Modal.Title>{formSettings[formMode][0]}</Modal.Title></Modal.Header>
    <Modal.Body className="p-5 banbg-3">
      {renderFeedback()}
    </Modal.Body>    
  </Modal>;

}

export default FeedbackPopup;