import React,{useEffect,useState} from 'react';

import {Modal} from 'react-bootstrap';

const HikmaPDF = (props) => {

  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    // Don't use iframe thing on mobiles for pdf links.
    if (window.innerWidth < 576) {
      if (props.pdf && props.pdf.slice(-3) == 'pdf') {
        setShowModal(false);
        var link = document.createElement("a");
        link.setAttribute('download', props.pdf);
        link.setAttribute('target', '_blank');
        link.href = props.pdf;
        document.body.appendChild(link);
        link.click();
        link.remove();
        if (props.onHide.length) {
          props.onHide();
        }
      }
    }
  }, [props.pdf]);

  return <Modal show={showModal} size="xl" centered onHide={props.onHide}>
    <Modal.Header  className="py-0" closeButton><Modal.Title>{props.title}</Modal.Title></Modal.Header>
    <Modal.Body className="p-0 vh-80">
      <iframe style={{borderWidth: 0}} width="100%" height="100%" src={props.pdf}></iframe>
    </Modal.Body>
  </Modal>;

}

export default HikmaPDF;
