import React, {useState, useEffect} from 'react';

import {Row, Col, Image, Button, Carousel, Accordion} from 'react-bootstrap';

import FaqPopup from './FaqPopup';
import IntroVidPopup from './IntroVidPopup';
import Banner from './Banner';
import PDFPopup from './PDFPopup';


import PrescribingInformation from "./selectpdf/PrescribingInformation";
import AmnealPDF from "./selectpdf/prescribingpdf/AmnealPDF";
import HikmaPDF from "./selectpdf/prescribingpdf/HikmaPDF";

const Header = (props) => {

    const [pdf, setPdf] = useState(false);
    const [faq, showFaq] = useState(false);
    const [IntroVid, showIntroVid] = useState(false);
    const [ban, setBan] = useState(1);

    const showPDF = (e) => {
        e.preventDefault();
        setPdf(e.target.getAttribute("href"));
    }

    const [pdf1Title, setPdf1Title] = useState('');
    const [pdf2Title, setPdf2Title] = useState('');

    const [pdf1, setPdf1 ] = useState(false);

    const showPDF1 = (e) => {
        e.preventDefault();
        setPdf1(e.target.getAttribute("data-pdf"));
        setPdf1Title('AMNEAL PRESCRIBING INFORMATION');
    }
    const [pdf2, setPdf2 ] = useState(false);

    const showPDF2 = (e) => {
        e.preventDefault();
        setPdf2(e.target.getAttribute("data-pdf"));
        setPdf2Title('HIKMA PRESCRIBING INFORMATION');
    }

    const [pdf3, setPdf3 ] = useState(false);

    const showPDF3 = (e) => {
        e.preventDefault();
        setPdf1(e.target.getAttribute("data-pdf"));
        setPdf1Title('AMNEAL MEDICATION GUIDE');
    }

    const [pdf4, setPdf4 ] = useState(false);

    const showPDF4 = (e) => {
        e.preventDefault();
        setPdf2(e.target.getAttribute("data-pdf"));
        setPdf2Title('HIKMA MEDICATION GUIDE');
    }

    const [isShown, setIsShown] = useState(true);
    const [isShown2, setIsShown2] = useState(true);
    const toggleFIeldset = () => setIsShown(!isShown);
    const toggleFIeldset2 = () => setIsShown2(!isShown2);

    // const [pinf, setPinf] = useState(false);
    // const showPinf = (e) => {
    //     e.preventDefault();
    //     setPinf(e.target.getAttribute("href"));
    // }

    const triggerBan = (e) => {
        setBan(parseInt(e.target.getAttribute('ban')));
    }
    const scrollToBan = (e) => {
      document.getElementById('mobile-ban-' + e.target.getAttribute('ban')).scrollIntoView({behavior: "smooth"});
    }

    useEffect(() => {
        var vid = document.getElementById('banner_video');
        if (vid && ban !== 0) {
            vid.pause();
        }
    }, [ban]);

    return (<>
        <Row>
            <Col className="mb-1 py-1 header-top">
                <div className="d-none d-sm-block">
                    <Row>
                        <Col className="d-flex flex-column align-items-center justify-content-center logo-top">
                          <Row noGutters>
                            <Image fluid src="images/Header/header-logo.png"/>
                          </Row>
                          <Row noGutters className="d-flex justify-content-center w-100 text-center">
                              <Col lg={4}><Image src="images/Header/phone-icon.png"/> 1-866-997-3688</Col>
                              <Col lg={4}><Image src="images/Header/fax-icon.png"/> 1-866-470-1744</Col>
                          </Row>
                        </Col>
                        <Col className="text-right my-0 py-0">
                            <div className="piheader-1">

                                <div className="piheader-2 pl-0">Prescribing Information</div>
                                <div className="piheader-button_wrapper">
                                    <div className="piheader-button_wrapper-2">
                                        <Button className="btn btn-light top-button-2 btn-primary btn btn-primary w-50"
                                                href={`https://pp.jazzpharma.com/pi/xywav.en.USPI.pdf`}
                                                onClick={showPDF}>XYWAV</Button>
                                        <Button className="btn btn-light top-button-2 btn-primary btn btn-primary w-50"
                                                href={`https://pp.jazzpharma.com/pi/xyrem.en.USPI.pdf`}
                                                onClick={showPDF}>XYREM</Button>
                                    </div>
                                    <Button className="btn btn-light top-button-2 btn-primary btn btn-primary w-100"
                                            href="javascript:;"
                                            onClick={toggleFIeldset}
                                    >Authorized
                                        Generic Prescribing Information</Button>
                                    {!isShown &&
                                        <div className="piheader-button_wrapper-2-vertical">
                                            <Button className="btn btn-light btn-primary btn btn-primary w-100"
                                                    data-pdf={`https://pp.jazzpharma.com/pi/sodium-oxybate-hikma.en.USPI.pdf`}
                                                    onClick={showPDF2}>Hikma Prescribing Information</Button>
                                            <Button className="btn btn-light btn-primary btn btn-primary w-100"
                                                    data-pdf={`${process.env.PUBLIC_URL}/pdfs/sodium-oxybate-amneal.en.USPI.pdf`}
                                                    onClick={showPDF1}>Amneal Prescribing Information</Button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="piheader-1 ml-4">

                                <div className="piheader-2 pl-0">Medication Guide</div>
                                <div className="piheader-button_wrapper">
                                    <div className="piheader-button_wrapper-2">
                                        <Button className="btn btn-light top-button-2 btn-primary btn btn-primary w-50"
                                                href={`https://pp.jazzpharma.com/pi/xywav.en.MG.pdf`}
                                                onClick={showPDF}>XYWAV</Button>
                                        <Button className="btn btn-light top-button-2 btn-primary btn btn-primary w-50"
                                                href={`https://pp.jazzpharma.com/pi/xyrem.en.MG.pdf`}
                                                onClick={showPDF}>XYREM</Button>
                                    </div>
                                    <Button className="btn btn-light top-button-2 btn-primary btn btn-primary w-100"
                                            href="javascript:;"
                                            onClick={toggleFIeldset2}
                                    >Authorized
                                        Generic Medication Guide</Button>
                                    {!isShown2 &&
                                        <div className="piheader-button_wrapper-2-vertical">
                                            <Button className="btn btn-light btn-primary btn btn-primary w-100"
                                                    data-pdf={`https://pp.jazzpharma.com/pi/sodium-oxybate-hikma.en.MG.pdf`}
                                                    onClick={showPDF4}>Hikma Medication Guide</Button>
                                            <Button className="btn btn-light btn-primary btn btn-primary w-100"
                                                    data-pdf={`${process.env.PUBLIC_URL}/pdfs/sodium-oxybate-amneal.en.MG.pdf`}
                                                    onClick={showPDF3}>Amneal Medication Guide</Button>
                                        </div>
                                    }
                                </div>
                            </div>


                            <Image className="clickable" onClick={() => {
                                showFaq(true)
                            }} fluid src="images/Header/faq-icon.png" style={{height: "60px"}}/>

                        </Col>

                    </Row>
                </div>

                <div className="d-block d-sm-none">

                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <Row className="p-1 py-2">
                            <Col className="col-10 d-flex align-items-center logo-top"><Image fluid
                                                                                              src="images/Header/header-logo.png"/></Col>
                            <Col className="pl-0 col-2 text-center">
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                        data-target="#topmenunav" aria-controls="topmenunav" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </Col>
                        </Row>
                        <div className="collapse navbar-collapse flex-column" id="topmenunav">
                            <Col className="pb-2">
                                <Accordion>
                                  <Accordion.Toggle as={Button} variant="link" eventKey="prescribing-info">
                                    Prescribing Information
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="prescribing-info">
                                    <div>
                                      <div className="piheader-button_wrapper-2">
                                          <Button className="btn btn-light top-button-2 btn-primary btn btn-primary w-50 font-larger"
                                                  href={`https://pp.jazzpharma.com/pi/xywav.en.USPI.pdf`}
                                                  onClick={showPDF}>XYWAV</Button>
                                          <Button className="btn btn-light top-button-2 btn-primary btn btn-primary w-50 font-larger"
                                                  href={`https://pp.jazzpharma.com/pi/xyrem.en.USPI.pdf`}
                                                  onClick={showPDF}>XYREM</Button>
                                      </div>
                                      <p className="pt-2">Authorized Generic Prescribing Information</p>
                                      <div className="piheader-button_wrapper-2 flex-column">
                                          <Button className="btn btn-light btn-primary btn btn-primary w-100"
                                                  data-pdf={`https://pp.jazzpharma.com/pi/sodium-oxybate-hikma.en.USPI.pdf`}
                                                  onClick={showPDF2}>Hikma Prescribing Information</Button>
                                          <Button className="btn btn-light btn-primary btn btn-primary w-100"
                                                  data-pdf={`${process.env.PUBLIC_URL}/pdfs/sodium-oxybate-amneal.en.USPI.pdf`}
                                                  onClick={showPDF1}>Amneal Prescribing Information</Button>
                                      </div>
                                    </div>
                                  </Accordion.Collapse>
                                </Accordion>
                            </Col>
                            <Col className="pb-2">
                                <Accordion>
                                  <Accordion.Toggle as={Button} variant="link" eventKey="prescribing-info">
                                    Medication Guide
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="prescribing-info">
                                    <div>
                                      <div className="piheader-button_wrapper-2">
                                          <Button className="btn btn-light top-button-2 btn-primary btn btn-primary w-50 font-larger"
                                                  href={`https://pp.jazzpharma.com/pi/xywav.en.MG.pdf`}
                                                  onClick={showPDF}>XYWAV</Button>
                                          <Button className="btn btn-light top-button-2 btn-primary btn btn-primary w-50 font-larger"
                                                  href={`https://pp.jazzpharma.com/pi/xyrem.en.MG.pdf`}
                                                  onClick={showPDF}>XYREM</Button>
                                      </div>
                                      <p className="pt-2">Authorized Generic Medication Guide</p>
                                      <div className="piheader-button_wrapper-2 flex-column">
                                            <Button className="btn btn-light btn-primary btn btn-primary w-100"
                                                    data-pdf={`https://pp.jazzpharma.com/pi/sodium-oxybate-hikma.en.MG.pdf`}
                                                    onClick={showPDF2}>Hikma Medication Guide</Button>
                                            <Button className="btn btn-light btn-primary btn btn-primary w-100"
                                                    data-pdf={`${process.env.PUBLIC_URL}/pdfs/sodium-oxybate-amneal.en.MG.pdf`}
                                                    onClick={showPDF1}>Amneal Medication Guide</Button>
                                      </div>
                                    </div>
                                  </Accordion.Collapse>
                                </Accordion>
                            </Col>


                            <Col className="pb-2"><Image src="images/Header/phone-icon.png"/> 1-866-997-3688</Col>
                            <Col className="pb-2"><Image src="images/Header/fax-icon.png"/> 1-866-470-1744</Col>
                            <Col className="pb-4" onClick={() => {
                                showFaq(true)
                              }}><Image src="images/Header/faq-icon-circle.png" style={{height: "20px"}}/> FAQ
                            </Col>
                        </div>
                    </nav>

                </div>
            </Col>
        </Row>
        <Row className=" d-none d-sm-flex">
            <Col className="p-0 py-1">
                <div className="header py-3">
                    <Row noGutters style={{zIndex: 200}} className="semi-circle">
                        <Col sm={12} className={`banner-btn clickable ban1 ${ban === 1 ? 'banbg-1' : ''}`} ban="1"
                             onClick={triggerBan}>
                            XYWAV and <br/>
                            XYREM REMS
                            <Image src="images/Header/Icons-Buttons/icon-XYWAV-XYREM.png" ban="1"/>
                        </Col>


                        <Col sm={12} className={`banner-btn clickable ban2 ${ban === 2 ? 'banbg-2' : ''}`} ban="2"
                             onClick={triggerBan}>
                            PRESCRIBER<br/>
                            RESOURCES
                            <Image src="images/Header/Icons-Buttons/icon-prescriber-resources.png" ban="2"/>
                        </Col>

                        <Col sm={12} className={`banner-btn clickable ban3 ${ban === 3 ? 'banbg-3' : ''}`} ban="3"
                             onClick={triggerBan}>
                            PATIENT/CAREGIVER<br/>
                            RESOURCES
                            <Image src="images/Header/Icons-Buttons/icon-patient-caregiver.png" ban="3"/>
                        </Col>
                    </Row>

                    <div style={{zIndex: 100}} className={`w-100 h-100 banbg-${ban}`}>
                        <Carousel activeIndex={ban} controls={false} indicators={false} interval={null} touch={false}>
                            <Carousel.Item><Banner ban={0}/></Carousel.Item>
                            <Carousel.Item><Banner tabSelect={props.tabSelect} ban={1}/></Carousel.Item>
                            <Carousel.Item><Banner tabSelect={props.tabSelect} ban={2}/></Carousel.Item>
                            <Carousel.Item><Banner ban={3}/></Carousel.Item>
                        </Carousel>
                    </div>

                </div>
            </Col>
        </Row>


        <div className={`d-block d-sm-none w-100 h-100 banbg-${ban} mobile header-banner`} style={{minHeight: 120}}>
            <Row noGutters style={{zIndex: 200}} className="semi-circle mobile">
                <Col sm={12} className={`banner-btn clickable ban1`} ban="1"
                     onClick={scrollToBan}>
                    XYWAV and <br/>
                    XYREM REMS
                    <Image src="images/Header/Icons-Buttons/icon-XYWAV-XYREM.png" ban="1"/>
                </Col>


                <Col sm={12} className={`banner-btn clickable ban2`} ban="2"
                     onClick={scrollToBan}>
                    PRESCRIBER<br/>
                    RESOURCES
                    <Image src="images/Header/Icons-Buttons/icon-prescriber-resources.png" ban="2"/>
                </Col>

                <Col sm={12} className={`banner-btn clickable ban3`} ban="3"
                     onClick={scrollToBan}>
                    PATIENT/CAREGIVER<br/>
                    RESOURCES
                    <Image src="images/Header/Icons-Buttons/icon-patient-caregiver.png" ban="3"/>
                </Col>
            </Row>

            <Carousel activeIndex={ban} controls={false} indicators={false} interval={null} onSelect={setBan} touch={false}>
                {/*<Carousel.Item><Banner ban={0}/></Carousel.Item>*/}
                <Carousel.Item id="mobile-ban-1"><Banner tabSelect={props.tabSelect} ban={1}/></Carousel.Item>
                <Carousel.Item id="mobile-ban-2"><Banner tabSelect={props.tabSelect} ban={2}/></Carousel.Item>
                <Carousel.Item id="mobile-ban-3"><Banner ban={3}/></Carousel.Item>
            </Carousel>
        </div>

        {pdf !== false && <PDFPopup pdf={pdf} onHide={() => setPdf(false)}/>}
        {/*{pinf !== false && <PrescribingInformation pinf={pinf} onHide={() => setPinf(false)}/>}*/}
        {pdf1 !== false && <AmnealPDF pdf={pdf1} title={pdf1Title} onHide={() => setPdf1(false)}/>}
        {pdf2 !== false && <HikmaPDF pdf={pdf2} title={pdf2Title} onHide={() => setPdf2(false)}/>}

        {faq && <FaqPopup onHide={() => {
            showFaq(false);
        }}/>}
        {IntroVid && <IntroVidPopup onHide={() => {
            showIntroVid(false);
        }}/>}

    </>);
}

export default Header;
