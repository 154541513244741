import React,{useState} from 'react';

import {Row, Col, Card, Table, Image} from 'react-bootstrap';

import PresInfoPopup from './../PresInfoPopup';
import PDFPopup from './../PDFPopup';
import EPrescribePopup from './../EPrescribePopup';

const MidTab5 = (props) => {

    const [presInfo,showPresInfo] = useState(false);
    const [pdf, setPdf] = useState(false);
    const [ePrescribe,showEPrescribe] = useState(false);

    const showPDF = (e) => {
        e.preventDefault();
        setPdf(e.target.getAttribute("href"));
    }

  return <>
    <Row>
      <Col className="col-12 col-md-4 title col">PRESCRIBE<br />XYWAV and XYREM
        <div className="title-details mt-2">
            <p>Utilize the resources here to understand the benefits and risks of XYWAV
                and XYREM and to prescribe XYWAV and XYREM. For more information please call the XYWAV and XYREM REMS
                toll free at 1-866-997-3688.</p>
            <p className="mt-5"><b>To prescribe XYWAV and XYREM, both prescriber and patient must be enrolled in the
                XYWAV and XYREM REMS.</b></p>
        </div>
      </Col>
      <Col className="col-12 col-md-8 text-right bg-light p-45 col">

        <Row noGutters>
          <Col className="col-12 col-sm-5 text-center blue-text col flex-column d-flex align-items-center">
            <label className="red px-auto py-1 mt-2 mb-4 mb-sm-0 mt-sm-0">Electronic Prescription Forms</label>
            <p className="v-spacer-1 d-none d-sm-block"></p>
            <Card variant="light" className="flex-grow-1">
              <Card.Body className="text-center">
                <Table responsive borderless>
                  <tbody>
                  <tr>
                    <td className="w-30 p-0 pt-2"><Image src={`${process.env.PUBLIC_URL}/images/Body/icon-computer-small.png`} /></td>
                    <td className="p-0 pl-1 text-left">
                        <b>To submit the XYWAV or XYREM Prescription Form online for your patient, select the ePrescribe Now button below</b>
                    </td>
                  </tr>
                  <tr>
                      <td colSpan="2" className="h-10"></td>
                  </tr>
                  <tr>
                      <td className="w-30 p-0"><Image src={`${process.env.PUBLIC_URL}/images/Body/icon-check.png`} /></td>
                      <td className="p-0 pl-1 text-left"><b>Completed electronic prescriptions are submitted directly to
                          the XYWAV and XYREM REMS</b>
                      </td>
                  </tr>
                  <tr>
                      <td colSpan="2" className="h-10"></td>
                  </tr>
                  <tr>
                      <td colSpan="2" className="text-center">
                          <div className="btn-line">
                              <a className="btn btn-orange btn-big" href="#" onClick={(e) => {e.preventDefault();showEPrescribe(true);}}>
                                  <i className="btn-label label-new"></i><b>ePrescribe Now</b>
                                  <Image className="icon-arr" src={`${process.env.PUBLIC_URL}/images/Body/icon-arr.png`} />
                              </a>
                          </div>
                          <div className="btn-line">
                              <a className="btn buttons btn-big" href={`${process.env.PUBLIC_URL}/pdfs/JazzPharmaceuticals_ePrescribe Platform Manual.pdf`} onClick={showPDF}>ePrescribe Help Guide</a>
                          </div>
                      </td>
                  </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

          </Col>
          <Col className="col-12 col-sm-1 text-center col icon-col"><i className="icon-or"></i></Col>
          <Col className="col-12 col-sm-5 text-center blue-text col flex-column d-flex align-items-center">
              <label className="red px-auto py-1">Download Prescription Forms</label>
              <p className="black-text f-085 v-spacer-1">If you prefer to not use electronic prescriptions, the XYWAV
                  and XYREM Prescription Forms are available to download, but must be printed, signed, and sent by Fax
                  or Mail to the XYWAV and XYREM REMS.</p>
            <Card variant="light" className="flex-grow-1">
              <Card.Body className="pb-0">
              <Table responsive borderless>
                 <tbody>
                  <tr>
                      <td className="w-30 p-0">&nbsp;</td>
                      <td className="w-30 p-0 pt-2"><Image src={`${process.env.PUBLIC_URL}/images/Body/icon-download.png`} /></td>
                      <td className="p-0 pl-1 text-left">
                        <a href={`${process.env.PUBLIC_URL}/pdfs/XYWAV_Prescription_Form.pdf`} onClick={showPDF}>Download the <b href={`${process.env.PUBLIC_URL}/pdfs/XYWAV_Prescription_Form.pdf`} onClick={showPDF}>XYWAV</b> Prescription Form</a>
                      </td>
                  </tr>
                  <tr>
                      <td colSpan="3" className="h-10"></td>
                  </tr>
                  <tr>
                      <td className="w-30 p-0">&nbsp;</td>
                      <td className="w-30 p-0 pt-2"><Image src={`${process.env.PUBLIC_URL}/images/Body/icon-download.png`} /></td>
                      <td className="p-0 pl-1 text-left">
                        <a href={`${process.env.PUBLIC_URL}/pdfs/XYREM_Prescription_Form.pdf`} onClick={showPDF}>Download
                            the <b href={`${process.env.PUBLIC_URL}/pdfs/XYREM_Prescription_Form.pdf`} onClick={showPDF}>XYREM</b> Prescription Form</a>
                     </td>
                  </tr>
                  <tr>
                      <td colSpan="3" className="h-10"></td>
                  </tr>
                  <tr>
                      <td className="w-30 p-0">&nbsp;</td>
                      <td className="w-30 p-0"><Image src={`${process.env.PUBLIC_URL}/images/Body/icon-print-sign.png`} /></td>
                      <td className="p-0 pl-1 text-left">
                          Print and Sign the completed XYWAV or XYREM Prescription Form for your Patient
                      </td>
                  </tr>
                  <tr>
                      <td colSpan="3" className="h-10"></td>
                  </tr>
                  <tr>
                      <td className="w-30 p-0">&nbsp;</td>
                      <td className="w-30 p-0"><Image src={`${process.env.PUBLIC_URL}/images/Body/icon-fax.png`} /></td>
                      <td className="p-0 pl-1 text-left">
                        <b>Fax to:<br />XYWAV and XYREM REMS</b><br />1-866-470-1744
                        (toll free)
                      </td>
                  </tr>
                  <tr>
                      <td className="w-30 p-0">&nbsp;</td>
                      <td className=""></td>
                  </tr>
                  <tr>
                      <td className="w-30 p-0">&nbsp;</td>
                      <td className="w-30 p-0 pt-3"><Image src={`${process.env.PUBLIC_URL}/images/Body/icon-mail.png`} /></td>
                      <td className="p-0 pl-1 text-left"><b>Mail to:<br />XYWAV and XYREM REMS</b> <br />PO
                          Box 66589<br />St. Louis, MO 63166-6589
                      </td>
                  </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

          </Col>
        </Row>

          {presInfo && <PresInfoPopup onHide={() => {showPresInfo(false);}} />}
          {pdf !== false && <PDFPopup pdf={pdf} onHide={() => setPdf(false)}/>}
          {ePrescribe && <EPrescribePopup onHide={() => {showEPrescribe(false);}} />}


      </Col>
    </Row>
  </>
}

export default MidTab5;
