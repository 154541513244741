import React,{useState} from 'react';
import {Row,Col,Image} from "react-bootstrap";

import PDFPopup from './PDFPopup';
import XyremQuickStartGuide from "./selectpdf/XYREMQuickStartGuide";
import XyremPatientsCaregiver from "./selectpdf/XYREMPatientsCaregiver";
import XywavQuickStartGuide from "./selectpdf/XYWAVQuickStartGuide";
import XywavPatientsCaregiver from "./selectpdf/XYWAVPatientsCaregiver";


const Footer = (props) => {

  const [pdf, setPdf] = useState(false);
  const year = (new Date()).getFullYear();

  const showPDF = (e) => {
    e.preventDefault();
    setPdf(e.target.getAttribute("href"));
  }
  const [xyrpc, setXyrpc] = useState(false);
  const showXyrpc = (e) => {
    e.preventDefault();
    setXyrpc(e.target.getAttribute("href"));
  }

  const [xywpc, setXywpc] = useState(false);
  const showXywpc = (e) => {
    e.preventDefault();
    setXywpc(e.target.getAttribute("href"));
  }

  const [xyrqsg, setXyrqsg] = useState(false);
  const showXyrqsg = (e) => {
    e.preventDefault();
    setXyrqsg(e.target.getAttribute("href"));
  }

  const [xywqsg, setXywqsg] = useState(false);
  const showXywqsg = (e) => {
    e.preventDefault();
    setXywqsg(e.target.getAttribute("href"));
  }

  return (<Row>
    <Col className="footer p-4">
      <Row>
        <Col className="col-12 col-xl-8 text-left">
          <Row>
            <Col className="col-12 col-lg-5">
              <div className="subhead">Prescriber Materials</div>
              <ul>
                <li><a href={`${process.env.PUBLIC_URL}/pdfs/XYWAV_Prescription_Form.pdf`} onClick={showPDF}>XYWAV Prescription Form</a></li>
                <li><a href={`${process.env.PUBLIC_URL}/pdfs/XYREM_Prescription_Form.pdf`} onClick={showPDF}>XYREM Prescription Form</a></li>
                <li><a href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Prescriber_Enrollment_Form.pdf`} onClick={showPDF}>XYWAV and XYREM REMS Prescriber Enrollment Form</a></li>
                <li><a href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Patient_Enrollment_Form.pdf`} onClick={showPDF}>XYWAV and XYREM REMS Patient Enrollment Form</a></li>
                <li><a href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Prescriber_Brochure.pdf`} onClick={showPDF}>XYWAV and XYREM REMS Prescriber Brochure</a></li>
              </ul>
            </Col>
            <Col className="col-12 col-lg-5">
              <div className="subhead">Patient Materials</div>
              <ul>
                <li><a href="javascript:;" onClick={showXyrqsg}>XYREM Patient Quick Start Guide</a></li>
                <li><a href="javascript:;" onClick={showXyrpc}>XYREM Brochure for Pediatric Patients and their Caregivers</a></li>
                <li><a href="javascript:;" onClick={showXywpc}>XYWAV Brochure for Pediatric Patients and their Caregivers</a></li>
                <li><a href="javascript:;" onClick={showXywqsg}>XYWAV Patient Quick Start Guide</a></li>
              </ul>
            </Col>
          </Row>

        </Col>

        <Col className="col-4 d-none d-xl-inline text-right"><Image fluid src="images/Footer/footer-logo.png" /></Col>

      </Row>

      <Row className="d-flex d-xl-none">
        <Col className="col-12 col-sm-6 text-center text-sm-left"><Image fluid src="images/Footer/Jazz-Logo.png" /></Col>
        <Col className="col-12 col-sm-6 text-right"><Image fluid src="images/Footer/footer-logo.png" style={{maxHeight: "40px!important"}} /></Col>
        <Col>
          &copy; {year} Jazz Pharmaceuticals
        </Col>
      </Row>

      <Row className="d-none d-xl-flex">
        <Col className="col-7 copyright d-flex align-items-end">
          &copy; {year} Jazz Pharmaceuticals
        </Col>
        <Col className="col-5 text-right"><Image fluid src={`${process.env.PUBLIC_URL}/images/Footer/Jazz-Logo.png`} /></Col>
      </Row>

      {pdf!==false && <PDFPopup pdf={pdf} onHide={() => setPdf(false)} />}
      {xyrqsg !== false && <XyremQuickStartGuide xyrqsg={xyrqsg} onHide={() => setXyrqsg(false)}/>}
      {xyrpc !== false && <XyremPatientsCaregiver xyrpc={xyrpc} onHide={() => setXyrpc(false)}/>}
      {xywqsg !== false && <XywavQuickStartGuide xywqsg={xywqsg} onHide={() => setXywqsg(false)}/>}
      {xywpc !== false && <XywavPatientsCaregiver xywpc={xywpc} onHide={() => setXywpc(false)}/>}

    </Col>
  </Row>);

}

export default Footer;
