import React from 'react';

import {Modal, Button, Image} from 'react-bootstrap';

const HomePopup = (props) => {

  return <Modal show={true} size="lg" centered onHide={props.onHide}>
    <div className="welcome-popup">
      <div className="welcome-popup-wrapper">
        <div className="wpopup-header mb-2">
          <div className="line-1">Welcome to the</div>
          <div className="line-2">XYWAV and XYREM REMS website</div>
        </div>
        <div className="wpopup-body mt-4">
          <div className="wpopup-items">
            <div className="wpopup-item">
              <div className="wpi-ico">
                <i className="label-new"></i>
                <Image src={`${process.env.PUBLIC_URL}/images/Pop-up/wpi-ico-1.svg`} className="wpi-ico-img" />
              </div>
              <div className="wpi-text">The website includes ePrescribing as an option for REMS certified prescribers to submit the XYWAV or XYREM Prescription Forms for patients
              </div>
            </div>
            <div className="wpopup-item">
              <div className="wpi-ico">
                <i className="label-new"></i>
                <Image src={`${process.env.PUBLIC_URL}/images/Pop-up/wpi-ico-2.svg`} className="wpi-ico-img" />
              </div>
              <div className="wpi-text">The ePrescribing option requires REMS certified prescribers to register per DEA regulations in the electronic prescribing for controlled substances (EPCS) for ePrescribing Platform Access
              </div>
            </div>
            <div className="wpopup-item">
              <div className="wpi-ico">
                <Image src={`${process.env.PUBLIC_URL}/images/Pop-up/wpi-ico-3.svg`} className="wpi-ico-img" />
              </div>
              <div className="wpi-text">Prescriber and patient REMS enrollment is required, and available to complete
                online.
              </div>
            </div>
            <div className="wpopup-item">
              <div className="wpi-ico">
                <Image src={`${process.env.PUBLIC_URL}/images/Pop-up/wpi-ico-4.svg`} className="wpi-ico-img" />
              </div>
              <div className="wpi-text">All XYWAV and XYREM REMS materials are available for view or download.</div>
            </div>
          </div>
          <div className="btn-line text-center mt-3">
            <Button variant="light" onClick={props.onHide} className="btn-wpopup">
              Proceed to Site
            </Button>
          </div>
        </div>
      </div>
    </div>




  </Modal>;

}

export default HomePopup;