import React, {useState} from 'react';

import {Modal} from 'react-bootstrap';
import PDFPopup from '../PDFPopup';

const XyremPatientsCaregiver = (props) => {

  const [pdf, setPdf] = useState(false);

  const showPDF = (e) => {
    e.preventDefault();
    setPdf(e.target.getAttribute("data-pdf"));
    console.log(e.target)
  }

  return <>
    <Modal show={true} size="xl" centered onHide={props.onHide}>
      <Modal.Header  className="py-0" closeButton><Modal.Title>XYWAV and XYREM REMS</Modal.Title></Modal.Header>
      <Modal.Body className="access-modal p-0 vh-80">
        <div className="select-language">
          <h1>Select Language</h1>
          <div className="sl-wrapper">
            <div className="sl-item">
              <div className="sl-title">English</div>
              <div className="sl-img">
                <img src={`${process.env.PUBLIC_URL}/images/img/XYREM_REMS_Brochure_for_Peds_and_Caregivers_EN.jpg`}
                     data-pdf={`${process.env.PUBLIC_URL}/pdfs/XYREM_REMS_Brochure_for_Peds_and_Caregivers_EN.pdf`}
                     onClick={showPDF} alt="" />
              </div>
            </div>
            <div className="sl-item">
              <div className="sl-title">Spanish</div>
              <div className="sl-img">
                <img src={`${process.env.PUBLIC_URL}/images/img/XYREM_REMS_Brochure_for_Peds_and_Caregivers_ESUS.jpg`}
                     data-pdf={`${process.env.PUBLIC_URL}/pdfs/XYREM_REMS_Brochure_for_Peds_and_Caregivers_ESUS.pdf`}
                     onClick={showPDF} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>;

    {pdf !== false && <PDFPopup pdf={pdf} onHide={() => setPdf(false)}/>}
  </>
}

export default XyremPatientsCaregiver;